@import "../wfm_variables";

.breeze {
  p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
    border-color: $border-color;
  }

  p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext:enabled:focus {
    border-color: $input-focus-border-color;
  }

  .p-inputtext:enabled:focus {
    border-color: $input-focus-border-color;
    box-shadow: none;
  }

  .p-component:disabled {
    opacity: 1;
    background-color: #eeeeee;
  }

  input.p-inputtext.p-component.p-element.p-inputnumber-input {
    border-radius: 0px;
  }
}
