.app-content{

  .p-component{
    font-family: "Open Sans", Arial, sans-serif !important;
  }
  .p-multiselect.p-component{
    font-family: "Open Sans", Arial, sans-serif !important;
    font-size: 14px;
    //display:block;
    height: 36px;
    //padding-left: 10px;
    //padding-top: 7px;
    color: #333;
    //background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
    min-height: 36px;
    width:100%;
    .p-multiselect-panel .p-multiselect-items{
      padding: 2px 0;
    }
    //align-items: center;
    .p-multiselect-trigger{
      border-left: 0;
      right: 4px;
    }
    .p-multiselect-label{
      padding: 0;
      visibility: visible;
      line-height: 33px;
      margin-left: 10px;
    }

    .p-multiselect-item {
      padding: 0;
      margin: 0;
      height: 40px !important;
    }
    .ui-multiselected-empty-token{
      line-height: 34px;
      color: #9f9f9f;
    }
    .p-multiselect-item.ui-state-highlight{
        background-color: white;
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
      color:#212529;
    }
    &.ui-state-focus {
        // -moz-box-shadow: 0px 0px 5px #1f89ce;
        // -webkit-box-shadow: 0px 0px 5px #1f89ce;
        // box-shadow: 0px 0px 5px #1f89ce;
        // border-color: #237bc2;
    }

    .ui-multiselected-item-token{
        display: inline;
        color: #333;
    }
    .ui-multiselected-empty-token{
        align-items: center;
        color:#999;
    }
    .ui-multiselect-item-custom {
      // padding: 5px 0px 0px 5px
      height: 100%;
      width:100%;
      padding-top: 7px;
    }
    .ui-multiselect-item-custom:hover {
      background: #D9EBF5;
    }
    .p-multiselect-option-label{
        font-family: "Open Sans", Arial, sans-serif;
        font-size:14px;
        margin-top:4px;
        color: #333;
        display: inline-block;
        margin-left: 5px;
    }

  }
  .p-multiselect:not(.ui-state-disabled):hover{
    background-color: white;
    border-color: #237bc2;
  }
  .p-multiselect:not(.p-disabled).p-focus{
    box-shadow: none;
  }
  .p-multiselect:not(.ui-state-disabled):focus{
    box-shadow: none;
    border-color: #237bc2;
  }
  .p-multiselect:not(.ui-state-disabled):active{
    box-shadow: none;
    border-color: #237bc2;
  }
  .p-multiselect:not(.ui-state-disabled) .p-multiselect-trigger:hover {
    background-color: white;
  }
  .p-multiselect:not(.ui-state-disabled):hover .p-multiselect-trigger {
    background-color: white;
  }
  .p-multiselect-trigger-icon:before{
    content: "\e918";
    color: #007CBE;
    display: inline-block;
    font-family: 'icomoon';
    font-size: 12px;
    text-rendering: auto;
  }

  .p-multiselect.fullrow  .p-multiselect-item>.p-checkbox{
    display: none !important;
  }



  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    background: transparent;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{
    box-shadow: none;
  }

  p-multiSelect.filterable .p-multiselect-header {
       padding:0;
       background:white;
       border-bottom: none;
     }
  p-multiSelect.filterable .checkbox label.read-only .label-text{
    opacity: 0.6;
  }
  p-multiSelect.filterable .checkbox label{
    font-size: 13px;
    line-height: 22px;
    width:100%;
  }
  p-multiSelect.filterable .checkbox label .label-text{
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-right: 20px;
  }

  p-multiSelect.filterable .p-multiselect-empty-message{
    font-size: 13px;
    padding-left:12px;
    color: #526B7A;
  }
  p-multiSelect.filterable .p-multiselect-label-container{
    font-size: 13px;
  }
  p-multiSelect.filterable .p-multiselect-panel{
    border-radius: 0;
    box-sizing: content-box;
    margin-left: -1px;
    margin-top: -1px;
  }

  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper{
    max-width: 100%;
  }
}
