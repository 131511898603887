.checkbox {
  padding-left: 20px;
  cursor:pointer;
}
.checkbox.sm-left-pad {
  padding-left: 8px;
}
.checkbox label {
  position: relative;
  padding-left: 5px;
  font:400 14px "Open Sans Regular";
}
.checkbox label:before {
  content: "";
  display: inline-block;
  width:16px;
  height:16px;
  position: absolute;
  left: 0;
  top: 3px;
  margin-left: -20px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.checkbox:hover,
.checkbox:hover label:before,
.checkbox label:hover:before{
  border-color: #007CBE;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
}
.checkbox input[type="checkbox"]:focus + label:before {
  outline: 0;
}
.checkbox input[type="checkbox"] + label:after {
  color: transparent;
}
.checkbox input[type="checkbox"]:checked + label:before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23237bc2' stroke='none'%3E%3Cpath d='M18.7 4.6c-0.4-0.4-1-0.4-1.4 0l-8.8 8.8-4.8-4.6c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.6 5.2c0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l9.4-9.4c0.4-0.4 0.4-1 0-1.4z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  background-color: white;
}
.checkbox input[type="checkbox"]:checked:disabled + label:before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23C7D3D9' stroke='none'%3E%3Cpath d='M18.7 4.6c-0.4-0.4-1-0.4-1.4 0l-8.8 8.8-4.8-4.6c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.6 5.2c0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l9.4-9.4c0.4-0.4 0.4-1 0-1.4z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  cursor:default;
}
.checkbox input[type="checkbox"]:disabled + label:before {
  background-color: #eeeeee;
  cursor: default;
  border-color: #ccc;
}
.checkbox input[type="checkbox"]:disabled + label:hover:before{
  border-color: #ccc;
}
.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="checkbox"]:disabled + label{
  cursor: default;
}
.checkbox.checkbox-inline {
  margin-top: 0;
  margin-bottom: 5px;
}
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox]{
  margin-left:0;
}

/* view state */
.input-checkbox-view .selected{
  font-family: "OpenSans";
  font-weight: 600;
  margin-right:10px;
}
.input-checkbox-view .not-selected{
  margin-left:18px;
}
.input-checkbox-view {
  list-style: none;
  padding: 0;
  margin:0;
}
.input-checkbox-view li.selected:before {
  content: "";
  display: inline-block;
  height: 14px;
  width: 14px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23237bc2' stroke='none'%3E%3Cpath d='M18.7 4.6c-0.4-0.4-1-0.4-1.4 0l-8.8 8.8-4.8-4.6c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.6 5.2c0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l9.4-9.4c0.4-0.4 0.4-1 0-1.4z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  cursor:default;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 4px;
}
