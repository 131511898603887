.app-content{

  .p-component{
    font-family: "Open Sans", Arial, sans-serif !important;
  }
  .p-dropdown.p-component{
    font-family: "Open Sans", Arial, sans-serif !important;
    font-size: 13px ;
    //display:block;
    height: 36px;
    //padding-left: 10px;
    //padding-top: 7px;
    color: #333;
    //background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
    min-height: 36px;
    width:100%;
    .p-dropdown-panel .p-dropdown-items{
      padding: 2px 0;
    }
    //align-items: center;
    .p-dropdown-trigger{
      border-left: 0;
      right: 4px;
    }
    .p-dropdown-label{
      padding: 0;
      visibility: visible;
      line-height: 33px;
      margin-left: 10px;
    }

    .p-dropdown-item {
      padding: 7px 9px 5px 9px;
      margin: 0;
      height: 40px !important;
    }
    .ui-multiselected-empty-token{
      line-height: 34px;
      color: #9f9f9f;
    }
    .p-dropdown-item.ui-state-highlight{
        background-color: white;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
      color:#212529;
    }
    &.ui-state-focus {
        // -moz-box-shadow: 0px 0px 5px #1f89ce;
        // -webkit-box-shadow: 0px 0px 5px #1f89ce;
        // box-shadow: 0px 0px 5px #1f89ce;
        // border-color: #237bc2;
    }

    .p-dropdown-option-label{
        font-family: "Open Sans", Arial, sans-serif;
        font-size:14px;
        margin-top:4px;
        color: #333;
        display: inline-block;
        margin-left: 5px;
    }

  }
  .p-dropdown:not(.ui-state-disabled):hover{
    background-color: white;
    border-color: #237bc2;
  }
  .p-dropdown:not(.p-disabled).p-focus{
    box-shadow: none;
  }
  .p-dropdown:not(.ui-state-disabled):focus{
    box-shadow: none;
    border-color: #237bc2;
  }
  .p-dropdown:not(.ui-state-disabled):active{
    box-shadow: none;
    border-color: #237bc2;
  }
  .p-dropdown:not(.ui-state-disabled) .p-dropdown-trigger:hover {
    background-color: white;
  }
  .p-dropdown:not(.ui-state-disabled):hover .p-dropdown-trigger {
    background-color: white;
  }
  .p-dropdown-trigger-icon:before{
    content: "\e918";
    color: #007CBE;
    display: inline-block;
    font-family: 'icomoon';
    font-size: 12px;
    text-rendering: auto;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    background: #D9EBF5;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:focus{
    box-shadow: none;
  }


  p-dropdown .p-inputtext,p-dropdown .p-dropdown-item{
    font-size: 13px;
    line-height: 22px;
    width:100%;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    padding-right: 20px;

  }
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper{
    max-width: 100%;
  }
}
