@import "./wfm_variables";
$headerDarkTextColor: #2E2E2E;


.breeze {
  .ag-unselectable{
    --ag-selected-row-background-color: white;
    --ag-range-selection-background-color:transparent;
    --ag-range-selection-border-color: blue;

  }
   .editable-grid .ag-theme-balham .ag-unselectable{
    --ag-row-hover-color: transparent;
    --ag-selected-row-background-color: transparent;
  }

  .ag-theme-balham {
    font-family: "Open Sans";
    font-size: 14px;
    -webkit-font-smoothing:unset;
  }
  .ag-cell-wrapper{
    height:100%;
  }
  .cell-editable {
    cursor: pointer;
  }

  .customHeaderLabel.disabled {
    opacity: 0.5;
    cursor: default;
  }

  .editable-grid {
    .ag-theme-balham .ag-cell {
      text-align: right;
      font-size: 12px;
      line-height: 33px;
    }
  }

  .ag-theme-balham .ag-header {
    background: white;
    font: 700 11px "Open Sans";
    color: #98A2B3;
  }

  .ag-theme-balham .ag-cell {
    line-height: 43px;
  }

  .ag-center-cols-container,
  .ag-floating-top-container {
    min-width: 100% !important;
  }

  .ag-theme-balham .ag-row-selected {
    background-color: #F2F5F7;
  }

  .ag-header {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .07) !important;
    z-index: 1;
  }
  esp-table-header{
    width:100%;
  }

  .ag-theme-balham .ag-header-row:not(:first-child) .ag-header-cell, .ag-theme-balham .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
    border-top: 0;
  }

  .ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
    width: 0;
  }

  .ag-theme-balham .ag-body-container .ag-cell.ag-cell-focus,
  .ag-theme-balham .ag-cell.ag-cell-focus {
    height: 100%;
  }

  .ag-theme-balham .ag-body-container {
    min-width: 100%;
  }

  .ag-theme-balham .ag-header-viewport:after {
    content: "";
    position: absolute;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 1px 4px #BEBEBE;
    height: 1px;
    left: 0;
  }

  .ag-theme-balham .ag-header-cell,
  .ag-theme-balham .ag-header-group-cell {
    line-height: 32px;
  }


  .ag-theme-balham.no-column-borders .ag-center-cols-container .ag-cell.ag-cell-focus {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ag-theme-balham.no-column-borders .ag-center-cols-container .ag-cell {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .ag-theme-balham.no-column-borders .ag-center-cols-container .ag-cell:not(.ag-cell-focus) {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }


  .ag-theme-balham .ag-body-container .ag-cell,

  .ag-theme-balham .ag-cell {
    border-left-color: $dark-border-color;
    border-top-width: 0;
    border-bottom-width: 0;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: left;

    &.ag-cell-focus {
    //   border-left-color: blue;
    //   border-width: 1px;
    //   padding-top: 0;
    //   padding-bottom: 0;
      border: 1px solid blue;
    }


    &.calculated-field, &.imported-value {
      color: blue;
    }

    &.invalid {
      color: red;
    }


    &.locked {
      //color:grey;
      opacity: 0.5;
    }

    &.partial-field {
      font-style: italic;
    }

    &.negative-value {
      color: red;
    }

    &.closed-field {
      color: black;
      font-size: 0px;
      background-image: linear-gradient(-45deg, rgba(144, 144, 144, 0.5) 5%, rgba(255, 255, 255, 0.04) 5%, rgba(255, 255, 255, 0.04) 45%, rgba(144, 144, 144, 0.5) 55%, rgba(144, 144, 144, 0.5) 55%, rgba(255, 255, 255, 0.04) 55%, rgba(255, 255, 255, 0.04)) !important;
      background-size: 8px 8px !important;
    }
  }

  .ag-theme-balham .ag-pinned-left-floating-top .ag-cell:first-child,
  .ag-theme-balham .ag-pinned-left-cols-container .ag-cell:first-child {
    border-left-color: transparent;
    text-align: left;
    font-weight: 400;
  }

  .ag-theme-balham .ag-ltr .ag-cell-first-right-pinned {
    border-left-color: $dark-border-color;
  }

  .ag-theme-balham .ag-pinned-left-header,
  .ag-theme-balham .ag-ltr .ag-pinned-left-floating-top .ag-row,
  .ag-theme-balham .ag-ltr .ag-pinned-left-cols-container .ag-row {
    border-right: 2px solid lighten($dark-border-color, 10%);
    font-weight: 400;

    .ag-cell-focus {
      border: 1px solid blue;
    }
  }

  .ag-theme-balham .ag-ltr .ag-pinned-right-floating-top .ag-row,
  .ag-theme-balham .ag-ltr .ag-pinned-right-cols-container .ag-row {
    border-left: 2px solid lighten($dark-border-color, 10%);

    .ag-cell-focus {
      border: 1px solid blue;
    }
  }

  .ag-theme-balham .ag-pinned-right-header {
    border-left-width: 2px;
    border-left-color: lighten($dark-border-color, 10%);

    .ag-header-row .ag-header-cell:first-child,
    .ag-header-group-cell:first-child {
      border-left-color: $dark-border-color;
    }
  }

  .ag-theme-balham .ag-header-viewport .ag-header-cell {
    .ag-header-cell-label .ag-header-cell-text {
      margin-left: 0;
      margin-right: auto;
      line-height: 32px;
      color: #8C95A5;
      font-weight: 700;
    }
  }

  .ag-theme-balham .wfm-row-odd {
    background: #f7f7f7;
  }

  .ag-theme-balham .ag-ltr .ag-cell-last-left-pinned {
    border-right: 1px solid transparent;
  }

  .ag-header-group-cell {
    &.ag-header-group-cell-with-group {
      border-left: 1px solid $dark-border-color;

      &.black {
        .ag-header-group-text {
          color: $headerDarkTextColor;
          width: 100%;
        }
      }

      .ag-header-group-text {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
      }

      .customHeaderLabel {
        position: absolute;
        left: -1px;
      }
    }
  }

  .ag-header-cell {
    &.first-item {
      border-left: 0px;
    }

    &.partial-field {
      font-style: italic;
    }

    &.no-black {
      .customHeaderLabel {
        color: #98A2B3;
      }
    }

    &.black {
      .ag-header-cell-text {
        color: $headerDarkTextColor;
      }
    }

    &.text-right {
      .ag-header-cell-label {
        width: 100%;

        span {
          &.ag-header-cell-text {
            width: 100%;
          }
        }
      }
    }
  }

  .ag-header-viewport .ag-header-row:first-child:last-child {
    border-left: 0px solid $dark-border-color;
  }

  .ag-pinned-left-header .ag-header-group-cell.ag-header-group-cell-with-group {
    border-left: 0 !important;
  }

  .ag-cell-label-container {
    .ag-header-cell-text {
      font-size: 11px;
      font-weight: bold;
    }
  }

  .ag-pinned-left-cols-container {
    //font-weight: 600;

    .wfm-row-child {
      //font-weight: normal;

      .ag-cell {
        padding-left: 22px;
      }
    }
  }


  .ag-pinned-left-header {
    .ag-header-row {
      border-bottom: none;
    }
  }

  .ag-pinned-right-header {
    .ag-header-row {
      border-bottom: none;

      .ag-header-cell {
        border-left: 1px solid $dark-border-color;
      }
    }
  }

  .ag-header-viewport {
    .ag-header-container {
      .ag-header-row {
        border-bottom: none;

        .ag-header-cell {
          &.first-item {
            border-left: 1px solid $dark-border-color;
          }
        }
      }
    }
  }

  .ag-theme-balham .ag-root-wrapper {
    border: 0;
  }

  .ag-theme-balham .ag-checkbox-input-wrapper {
    width: 16px;
    height: 16px;
  }

  .ag-theme-balham .checkbox-disabled .ag-checkbox-input-wrapper {
    background: #e0e0e0;;
  }


  .ag-theme-balham .ag-checkbox-input-wrapper::after {
    font-weight: bold;
    content: ' ';
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='checkbox2' fill='%2393A4AA' fill-rule='nonzero'%3E%3Cpath d='M0,0 L0,16 L16,16 L16,0 L0,0 Z M15.1996,15.1996 L0.7996,15.1996 L0.7996,0.7996 L15.1996,0.7996 L15.1996,15.1996 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

    width: 16px;
    height: 16px;
  }

  .ag-theme-balham .ag-checkbox-input-wrapper.ag-checked::after {
    content: ' ';
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Echeckbox2%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='checkbox2' fill-rule='nonzero'%3E%3Cpath d='M0,0 L0,16 L16,16 L16,0 L0,0 Z M15.1996,15.1996 L0.7996,15.1996 L0.7996,0.7996 L15.1996,0.7996 L15.1996,15.1996 Z' id='Shape' fill='%2393A4AA'%3E%3C/path%3E%3Cpolygon id='Path' fill='%23007CBE' points='13.5 4.917 12.167 3.584 6.25 9.501 4.083 6.584 2.5 7.667 6 12.417'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .ag-theme-balham .ag-checkbox-input-wrapper.ag-indeterminate::after {
    content: '\f068';
    color: gray;
  }
  .ag-theme-balham .ag-cell-highlight{
    background-color: rgba(0, 0, 255, 0.4) !important;
  }
  .multi-cell-edit {
    --ag-range-selection-background-color:transparent;

    .ag-row-last .ag-cell.ag-cell.invalid,
    .ag-cell.ag-cell.invalid {
      //border-color: transparent;
    }
    .ag-row-first .ag-cell.invalid:not(.ag-cell-range-selected){
      border-top:none;
    }
    .ag-cell.ag-cell-highlight.ag-cell-range-selected{
      background: rgba(0, 0, 255, 0.3) !important;
    }
    .ag-cell:not(.ag-cell-range-single-cell){
      border-width: 1px;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell){
      //background: rgba(0, 0, 255, 0.2);
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left{
      border-left-color: var(--ag-range-selection-border-color, var(--ag-balham-active-color, blue)) !important;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom{
      border-bottom-color: var(--ag-range-selection-border-color, var(--ag-balham-active-color, blue)) !important;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right{
      border-right-color: var(--ag-range-selection-border-color, var(--ag-balham-active-color, blue)) !important;
    }
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top{
      border-top-color: var(--ag-range-selection-border-color, var(--ag-balham-active-color, blue)) !important;
    }
    .ag-cell.ag-cell.ag-cell-focus:not(.ag-cell-range-single-cell):not(.ag-cell-range-left){
      border-left-color: #c1bebe !important;
    }
    .ag-cell.ag-cell.ag-cell-focus:not(.ag-cell-range-single-cell):not(.ag-cell-range-top){
      border-top-color: transparent
    }
    .ag-cell.ag-cell.ag-cell-focus:not(.ag-cell-range-single-cell):not(.ag-cell-range-bottom){
      border-bottom-color: transparent
    }
    .ag-cell.ag-cell.ag-cell-focus:not(.ag-cell-range-single-cell):not(.ag-cell-range-right){
      border-right-color: transparent
    }
  }

  .editable-grid  .ag-theme-balham .ag-cell.invalid.ag-cell-data-changed{
    background: rgb(255 184 184) !important;
  }
  .editable-grid  .ag-theme-balham .ag-cell.invalid{
    background: #ffaeae36 !important;
    color:black !important;
  }
  .editable-grid:not(.multi-cell-edit) .ag-theme-balham .ag-cell.invalid.ag-cell-focus{
    border: 1px solid #c95056 !important;
  }
  .editable-grid .ag-theme-balham .ag-cell{
    background: transparent;
    transition: background-color 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
  .editable-grid:not(.multi-cell-edit)  .ag-theme-balham .ag-cell.invalid{
    border-left: 1px solid #c95056;
    border-right: 1px solid #c95056;
  }
  .editable-grid:not(.multi-cell-edit) .ag-theme-balham .ag-row-last .ag-cell.invalid{

    border-bottom: 1px solid #c95056;
  }

  .editable-grid:not(.multi-cell-edit) .ag-theme-balham .ag-row-first .ag-cell.invalid{
    border-top: 1px solid #c95056;
  }

  .ag-cell .validation-message{
    position: absolute;
    top: 9px;
    color: #d24444;
    font-size: 16px;
  }

  .editable-grid.read-only .ag-row{
    background: #f7f7f7;
  }

  .editable-grid .ag-theme-balham .read-only{
    background: #f7f7f7;
    //opacity: 0.6;
  }
  .ag-theme-balham .readonly-row{
    background: #f7f7f7;
  }
  .ag-theme-balham .editable-row{
    background: white;
  }
  .ag-group-contracted .ag-icon,.ag-group-expanded .ag-icon{
    padding:1px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ag-full-width-row{
    background: white !important;
  }
  .ag-icon-tree-indeterminate::before{
    font-family: icomoon;
    content: "\e97e";
    font-size:11px;
  }
  .ag-icon-tree-open::before{
    font-family: icomoon;
    content: "\e918";
    font-size:11px;
  }
  .ag-icon-tree-closed::before{
    font-family: icomoon;
    font-size:11px;
    content: "\e91a";
  }
  .ag-ltr .ag-group-expanded, .ag-ltr .ag-group-contracted{
    margin-right:4px
  }
  .ag-group-value{
    flex:1 1 auto;
  }
}
